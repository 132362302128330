var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pl-12 pr-12", attrs: { fluid: "" } },
    [
      _c(
        "v-layout",
        { attrs: { column: "", wrap: "" } },
        [
          _c("v-row", [
            _c(
              "p",
              {
                staticClass: "font-weight-black pl-3",
                staticStyle: { "font-size": "32px" }
              },
              [_vm._v("\n        " + _vm._s(_vm.title) + "\n      ")]
            )
          ]),
          _c(
            "v-row",
            { staticClass: "content" },
            [
              _c(
                "v-col",
                { staticStyle: { "max-width": "550px", "min-width": "550px" } },
                [
                  _c(
                    "div",
                    { ref: "applicationform", staticClass: "img-cropper" },
                    [
                      _vm.imgSrc
                        ? [
                            _c(
                              "v-img",
                              { attrs: { src: _vm.imgSrc } },
                              [
                                _c(
                                  "Moveable",
                                  _vm._b(
                                    {
                                      staticClass: "moveable",
                                      attrs: { width: "93px", height: "50px" },
                                      on: {
                                        drag: _vm.handleDrag,
                                        warp: _vm.handleWarp
                                      }
                                    },
                                    "Moveable",
                                    _vm.moveable,
                                    false
                                  ),
                                  [
                                    _c(
                                      "div",
                                      { ref: "print" },
                                      [
                                        _c("v-img", {
                                          staticClass: "mx-auto",
                                          staticStyle: {
                                            "background-color":
                                              "rgba(255, 255, 255, .4)"
                                          },
                                          attrs: {
                                            width: "100%",
                                            height: "100%",
                                            src: require("@/assets/img/applicationFormPrintingSample.png")
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ]
                        : _vm._e()
                    ],
                    2
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "v-row",
            [
              _vm.page !== 1
                ? _c(
                    "v-col",
                    { staticStyle: { "text-align": "center" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "font-weight-black",
                          staticStyle: { "font-size": "16px" },
                          attrs: {
                            align: "center",
                            width: "15vw",
                            "max-width": "300px",
                            color: "next",
                            dark: ""
                          },
                          on: { click: _vm.prevPage }
                        },
                        [_vm._v(_vm._s(_vm.$t("button.prevPage")))]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.checkLastPage()
                ? _c(
                    "v-col",
                    { staticStyle: { "text-align": "center" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "font-weight-black",
                          staticStyle: { "font-size": "16px" },
                          attrs: {
                            align: "center",
                            width: "15vw",
                            "max-width": "300px",
                            color: "next",
                            dark: ""
                          },
                          on: { click: _vm.nextPage }
                        },
                        [_vm._v(_vm._s(_vm.$t("button.nextPage")))]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              [
                _c(
                  "v-col",
                  { staticStyle: { "text-align": "center" } },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "font-weight-black",
                        staticStyle: { "font-size": "16px" },
                        attrs: {
                          align: "center",
                          width: "15vw",
                          "max-width": "300px",
                          color: "next",
                          dark: ""
                        },
                        on: {
                          click: function($event) {
                            return _vm.cancelEditFunction()
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.cancelButtonText))]
                    )
                  ],
                  1
                ),
                _vm.isEditing
                  ? _c(
                      "v-col",
                      { staticStyle: { "text-align": "center" } },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "font-weight-black",
                            staticStyle: { "font-size": "16px" },
                            attrs: {
                              align: "center",
                              width: "15vw",
                              "max-width": "300px",
                              color: "next",
                              dark: ""
                            },
                            on: {
                              click: function($event) {
                                return _vm.completeConfirm()
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("button.update")))]
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              [
                [
                  _vm.isCreating
                    ? _c(
                        "v-col",
                        { staticStyle: { "text-align": "center" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "font-weight-black",
                              staticStyle: { "font-size": "16px" },
                              attrs: {
                                align: "center",
                                width: "15vw",
                                "max-width": "300px",
                                color: "next",
                                dark: ""
                              },
                              on: {
                                click: function($event) {
                                  return _vm.beforeComplete()
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.completeButtonText))]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ]
              ]
            ],
            2
          ),
          _c("SimpleDialog", {
            attrs: {
              showDialog: _vm.showCompleteConfirmDialog,
              title: _vm.title,
              confirmText: _vm.$t("description.updatePrintingPosition"),
              negativeButtonTitle: _vm.$t("button.cancel"),
              positiveButtonTitle: _vm.$t("button.update"),
              onClickNegativeButton: _vm.closeCompleteConfirmDialog,
              onClickPositiveButton: _vm.beforeComplete
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }