<template>
  <v-container fluid class="pl-12 pr-12">
    <v-layout column wrap>
      <v-row>
        <p class="font-weight-black pl-3" style="font-size:32px">
          {{ title }}
        </p>
      </v-row>
      <v-row class="content">
        <v-col style="max-width:550px; min-width:550px;">
          <div class="img-cropper" ref="applicationform">
            <template v-if="imgSrc">
              <v-img :src="imgSrc">
                <Moveable
                  width="93px"
                  height="50px"
                  class="moveable"
                  v-bind="moveable"
                  @drag="handleDrag"
                  @warp="handleWarp"
                >
                  <div ref="print">
                    <v-img
                      style="background-color: rgba(255, 255, 255, .4);"
                      class="mx-auto"
                      width="100%"
                      height="100%"
                      src="@/assets/img/applicationFormPrintingSample.png"
                    />
                  </div>
                </Moveable>
              </v-img>
            </template>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="page !== 1" style="text-align: center">
          <!-- 前のページ -->
          <v-btn
            class="font-weight-black"
            align="center"
            width="15vw"
            max-width="300px"
            color="next"
            style="font-size:16px"
            dark
            @click="prevPage"
            >{{ $t('button.prevPage') }}</v-btn
          >
        </v-col>
        <v-col v-if="!checkLastPage()" style="text-align: center">
          <!-- 次のページ -->
          <v-btn
            class="font-weight-black"
            align="center"
            width="15vw"
            max-width="300px"
            color="next"
            style="font-size:16px"
            dark
            @click="nextPage"
            >{{ $t('button.nextPage') }}</v-btn
          >
        </v-col>
        <template>
          <v-col style="text-align: center">
            <!-- キャンセル -->
            <v-btn
              class="font-weight-black"
              align="center"
              width="15vw"
              max-width="300px"
              color="next"
              style="font-size:16px"
              dark
              @click="cancelEditFunction()"
              >{{ cancelButtonText }}</v-btn
            >
          </v-col>
          <v-col v-if="isEditing" style="text-align: center">
            <!-- 編集完了 -->
            <v-btn
              class="font-weight-black"
              align="center"
              width="15vw"
              max-width="300px"
              color="next"
              style="font-size:16px"
              dark
              @click="completeConfirm()"
              >{{ $t('button.update') }}</v-btn
            >
          </v-col>
        </template>
        <template>
          <template>
            <v-col v-if="isCreating" style="text-align: center">
              <!-- 登録 -->
              <v-btn
                class="font-weight-black"
                align="center"
                width="15vw"
                max-width="300px"
                color="next"
                style="font-size:16px"
                dark
                @click="beforeComplete()"
                >{{ completeButtonText }}</v-btn
              >
            </v-col>
          </template>
        </template>
      </v-row>

      <!-- 更新確認 -->
      <SimpleDialog
        :showDialog="showCompleteConfirmDialog"
        :title="title"
        :confirmText="$t('description.updatePrintingPosition')"
        :negativeButtonTitle="$t('button.cancel')"
        :positiveButtonTitle="$t('button.update')"
        :onClickNegativeButton="closeCompleteConfirmDialog"
        :onClickPositiveButton="beforeComplete"
      />
    </v-layout>
  </v-container>
</template>

<script>
import Moveable from 'vue-moveable';
import 'cropperjs/dist/cropper.css';
import SimpleDialog from '@/components/organisms/agency/SimpleDialog';

export default {
  name: 'PrintingPositionManagementTemplate',
  props: {
    images: Array,
    temporaryId: Number,
    onComplete: Function,
    onCancel: Function || null,
    type: String,
    cancelButtonText: String,
    completeButtonText: String,
    completeText: String,
    applicationFormDetails: Object,
    title: String,
  },
  components: {
    SimpleDialog,
    Moveable,
  },
  data() {
    return {
      imgSrc: null,
      data: null,
      page: 1,
      id: this.temporaryId,
      showFailureRegisterDialog: false,
      showCompleteConfirmDialog: false,
      cancelEditFunction: this.onCancel,
      moveable: {
        draggable: true,
        throttleDrag: 0,
        resizable: false,
        throttleResize: 1,
        keepRatio: true,
        scalable: false,
        throttleScale: 0,
        rotatable: false,
        throttleRotate: 0,
        pinchable: true, // ["draggable", "resizable", "scalable", "rotatable"]
        origin: false,
        snappable: true,
        bounds: { left: 0, top: 0, bottom: 0, right: 0 },
      },
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
    };
  },
  async mounted() {
    if (this.images && this.images.length > 0) this.setImage(this.page);

    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleDrag({ target, transform }) {
      target.style.transform = transform;
    },
    handleWarp({ target, transform }) {
      console.log('onWarp', transform);
      target.style.transform = transform;
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
      this.setDragRange();
    },
    nextPage() {
      ++this.page;
      this.setImage(this.page);
    },
    prevPage() {
      --this.page;
      this.setImage(this.page);
    },
    async setImage(page) {
      this.imgSrc = this.images[page];
      // rebuild cropperjs with the updated source
      if (this.$refs.cropper) await this.$refs.cropper.replace(this.imgSrc);
      this.setDragRange();
    },
    async setDragRange() {
      // PDFページの読み込みが完了するまで繰り返す
      const loader = setInterval(function() {
        const result = loadApplicationForm();
        if (result) clearInterval(loader);
      }, 100);
      // ロード完了判定
      const loadApplicationForm = () => {
        let result = false;
        const applicationform = this.$refs.applicationform.getBoundingClientRect();
        if (applicationform.height > 50) {
          this.moveable.bounds.left = applicationform.left + window.pageXOffset;
          this.moveable.bounds.right =
            this.moveable.bounds.left + applicationform.width;
          this.moveable.bounds.top = applicationform.top + window.pageYOffset;
          this.moveable.bounds.bottom =
            this.moveable.bounds.top + applicationform.height;

          result = true;
        }
        return result;
      };
    },
    checkLastPage() {
      return this.images.length - 1 === this.page;
    },
    completeConfirm() {
      this.$set(this, 'showCompleteConfirmDialog', true);
    },
    beforeComplete() {
      this.$set(this, 'showCompleteConfirmDialog', false);
      const positionOnScreen = this.$refs.print.getBoundingClientRect();
      const rowX =
        positionOnScreen.left + window.pageXOffset - this.moveable.bounds.left;
      const rowY =
        positionOnScreen.top + window.pageYOffset - this.moveable.bounds.top;
      const scale =
        this.applicationFormDetails.width /
        (this.moveable.bounds.right - this.moveable.bounds.left);
      this.onComplete(scale * rowX, scale * rowY, this.page);
    },
    closeCompleteConfirmDialog() {
      this.$set(this, 'showCompleteConfirmDialog', false);
    },
  },
  computed: {
    isCreating() {
      return this.type === 'create';
    },
    isEditing() {
      return this.type === 'edit';
    },
  },
  watch: {
    images: {
      handler(images) {
        if (this.images && this.images.length > 0) this.setImage(this.page);
      },
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
li {
  cursor: pointer;
  padding: 10px;
  border: solid #ddd 1px;
}

.moveable {
  font-family: 'Roboto', sans-serif;
  position: relative;
  width: 100px;
  height: 50px;
  text-align: center;
  font-size: 40px;
  margin: 0 auto;
  font-weight: 100;
  letter-spacing: 1px;
}
.moveable v-img {
  position: absolute;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  white-space: nowrap;
}

.img-cropper {
  position: relative;
}
</style>
